@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "Inter-Regular";
  src: url("./shared/fonts/Inter-Regular.ttf") format("truetype");
}

/* Slick dots customization */
.slick-dots li button:before {
  color: #81bc00;
  opacity: 0.3;
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  color: #81bc00;
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7FCFC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Inter-Regular", sans-serif;
  box-sizing: border-box;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#toastContainer {
  position: fixed;
  bottom: 0;
  z-index: 50;
  width: 100%;
  display: flex;
  padding: 10px 0 16px 0;
  background: transparent;
  /* pointer-events: none; */
}
